import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Container } from "react-bootstrap";

import { Toaster } from 'react-hot-toast';

import Layout from "./layout/Layout";

import Home from "./pages/Home";
import ClaimAirdrop from "./pages/ClaimAirdrop";
import CheckPhysical from "./pages/CheckPhysical";
import ClaimPhysical from "./pages/ClaimPhysical";
import Profile from "./pages/Profile";
import Admin from "./pages/Admin";

import Permissions from './components/Permissions'

import './styles/App.css';

const App = () => {

  const [provider, setProvider] = useState()
  const [library, setLibrary] = useState()
  const [signer, setSigner] = useState()
  const [account, setAccount] = useState()
  const [error, setError] = useState("")
  const [chainId, setChainId] = useState()

  const [airdrops, setAirdrops] = useState()
  const [userNFTs, setUserNFTs] = useState()
  const [dbNFTs, setDbNFTs] = useState()
  const [sign, setSign] = useState(null)
  const [isAuthorizedAdminWallet, setIsAuthorizedAdminWallet] = useState()

  const props = {
    provider, setProvider,
    library, setLibrary,
    signer, setSigner,
    account, setAccount,
    error, setError,
    chainId, setChainId,
    airdrops, setAirdrops,
    userNFTs, setUserNFTs,
    dbNFTs, setDbNFTs,
    sign, setSign,
    isAuthorizedAdminWallet, setIsAuthorizedAdminWallet
  }

  return (
    <Router>
      <Layout props={props}>
        <Container fluid>
          <Routes>
            <Route path="/" element={<Home />} exact />
            <Route path="/Claim-Airdrop" element={<ClaimAirdrop props={props} />} />
            <Route path="/Check-Physical" element={<CheckPhysical />} />
            <Route path="/Claim-Physical" element={<ClaimPhysical props={props} />} />
            <Route path="/Profile" element={<Profile props={props} />} />
            <Route path="/Admin" element={<Admin props={props} />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Permissions account={account} setIsAuthorizedAdminWallet={setIsAuthorizedAdminWallet} />
          <Toaster position="top-right" reverseOrder={false}/>
        </Container>
      </Layout>
    </Router>
  );
};

export default App;