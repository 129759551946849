import Button from 'react-bootstrap/Button';
import toast from 'react-hot-toast';

import { supabase } from '../supabaseClient'

import Web3Modal from "web3modal";

import emailjs from '@emailjs/browser';

const providerOptions = { };

const web3Modal = new Web3Modal({
  network: "mainnet",
  cacheProvider: true,
  providerOptions
});

const signMessage = async ({ library, account, message }) => {
  if (!library) return;
  try {
    const signature = await library.provider.request({
      method: "personal_sign",
      params: [message, account]
    });

    return {
      message,
      signature
    };
  } catch (error) { console.log(error); }
};

const sendEmail = (message) => {

  emailjs.send('service_zl9yg5p', 'template_2u2aqkj', {message: message}, 'pbvygcv648Mh62AzA')
    .then((result) => {
    }, (error) => {
        console.log(error.text);
    });
};


export default function SignMessage({library, account, mask_id, mask_type, setSign}) {

  const handleSign = async (e) => {
    e.preventDefault();

    var maskTypeFull;
    if (mask_type === 'TCP') { maskTypeFull = 'Cryptomasks' } else { maskTypeFull = 'Zodiacmasks' }

    const sig = await signMessage({ library: library, account: account, message: 'Commande ' + maskTypeFull + ' #' + mask_id });
    if (sig) {
        setSign(sig); // Confirmation
        console.log(sig);
        const { data, error } = await supabase
        .from('masks')
        .insert([{ identifier: mask_type + '-' + mask_id, mask_id: mask_id, mask_type: mask_type, wallet: account.toLowerCase(), message: sig.message, signature: sig.signature, status: 1 }]) // Update DB
        toast.success("Commande Cryptomasks #" + mask_id + " effectuée avec succès !");
        sendEmail(sig.message + " par " + account.toLowerCase());
    } else { toast.error("Commande Cryptomasks #" + mask_id + " non effectuée.");}
  };

  return (
    <form onSubmit={handleSign}>
        <Button variant="primary" type="submit">Commander la version physique</Button>
    </form>
  );
}