import { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, Button, Card } from "react-bootstrap";

import { supabase } from '../supabaseClient'
import Meta from '../components/Meta'
import SignPhysical from '../components/SignPhysical';
import Account from '../components/Account';
import WalletConnect from '../components/WalletConnect';

import BlacklistedMasks from '../assets/lists/bl-masks.json';
import BlacklistedZodiacMasks from '../assets/lists/bl-zodiacmasks.json';
import WhitelistedZodiacMasks from '../assets/lists/wl-zodiacmasks.json';

import { Network, Alchemy } from 'alchemy-sdk';

const settings = {
    apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
    network: Network.ETH_MAINNET,
};

const alchemy = new Alchemy(settings);
const TCPSC = process.env.REACT_APP_TCP_SC_ADDRESS;
const ZMSC = process.env.REACT_APP_ZM_SC_ADDRESS;

const pageTitle = 'The Cryptomasks Project - Claim Service - Physical Claim'

function ClaimZodiacPhysical({ props }) {

    const {
        provider,
        library,
        signer,
        account,
        chainId,
        userNFTs, setUserNFTs,
        sign, setSign
    } = props

    const getNFTs = useCallback(async () => {
        if (!library | !account) return;
        try {
            const nfts = await alchemy.nft.getNftsForOwner(account, {
                contractAddresses: [TCPSC, ZMSC],
            });
            setUserNFTs(nfts.ownedNfts);
        } catch (error) {
            console.error('Error during getting user NFTs', error);
        }
    }, [account]);

    useEffect(() => {
        getNFTs();
    }, [getNFTs]);

    const SingleNFT = ({ nft }) => {

        //const [maskIPFSDatas, setMaskIPFSDatas] = useState({});
        const [maskDBDatas, setMaskDBDatas] = useState({});
        const [isAuthorizedWallet, setIsAuthorizedWallet] = useState(false);

        useEffect(() => {
            //getMaskIPFSDatas(mask_id).then(setMaskIPFSDatas);
            getMaskDBDatas(nft.tokenId, nft.contract.name === 'TheCryptomasksProject' ? 'TCP' : 'ZM').then(setMaskDBDatas);
            getIsAuthorizedWallet(account).then(setIsAuthorizedWallet);
        }, [nft.tokenId]);

        // Vérif BL
        var BL;
        if (nft.contract.name === 'TheCryptomasksProject') { BL = BlacklistedMasks } else { BL = BlacklistedZodiacMasks }
        var isBL = BL.some(mask => parseInt(mask) === parseInt(nft.tokenId));

        return (<Col sm={4}><Card className="mb-4">
            <Card.Img variant="top" src={`https://assets.thecryptomasks.xyz/images/${nft.contract.name === 'TheCryptomasksProject' ? 'TheCryptomasksProject' : 'Zodiacmasks'}/${nft.tokenId}.jpg`} />
            <Card.Body>
                <Card.Title>{nft.contract.name === 'TheCryptomasksProject' ? 'Cryptomasks' : 'Zodiacmasks'} #{nft.tokenId}</Card.Title>
                {maskDBDatas.status === 0 && isBL === false && (nft.contract.name == 'TheCryptomasksProject' || (nft.contract.name == 'Zodiacmasks' && WhitelistedZodiacMasks.some(mask => parseInt(mask) === parseInt(nft.tokenId)))) ? ((isAuthorizedWallet === true) ? ( /*<Button variant="primary" disabled>Commande possible dès l'année prochaine</Button>*/ <SignPhysical mask_id={nft.tokenId} mask_type={nft.contract.name === 'TheCryptomasksProject' ? 'TCP' : 'ZM'} library={library} account={account} setSign={setSign} />) : (<Button variant="primary" disabled>Commande possible dès l'année prochaine</Button>)) : null}
                {maskDBDatas.status === 0 && isBL === false && (nft.contract.name == 'Zodiacmasks' && !WhitelistedZodiacMasks.some(mask => parseInt(mask) === parseInt(nft.tokenId))) && (<Button variant="danger" disabled>Zodiacmask non éligible à la version physique</Button>)}
                {maskDBDatas.status === 0 && isBL === true ? (<Button variant="danger" disabled>Commande impossible (mask non éligible)</Button>) : null}
                {maskDBDatas.status === 1 ? (<Button variant="success" disabled>Version physique commandée le {new Date(maskDBDatas.claim_date).toLocaleDateString("fr-FR")}</Button>) : null}
            </Card.Body>
        </Card></Col>)
    }

    const ListNFTs = () => {
        const listItems = userNFTs.map((nft, index) => <SingleNFT key={index} nft={nft} />);
        return (<Row className="justify-content-center"><p className="text-danger">La période de claim est terminée !</p></Row>);
        //return (<Row className="justify-content-center">{listItems}</Row>);
    }

    const getMaskDBDatas = async (mask_id, mask_type) => {
        try {
            let { data, error, status } = await supabase
                .from('check_mask_status')
                .select('*')
                .eq('mask_id', mask_id)
                .eq('mask_type', mask_type)

            if (error && status !== 406) { throw error }

            if (data.length >= 1) {
                return (data[0])
            } else { return ({ wallet: null, status: 0, claim_date: null }) }

        } catch (error) { console.log(error.message) } finally { }
    }

    const getIsAuthorizedWallet = async (account) => {
        try {
            let { data, error, status } = await supabase.rpc('is_authorized_physical_wallet', { wallet: account })

            if (error && status !== 406) { throw error }
            return (data)
        } catch (error) { console.log(error.message) } finally { }
    }

    const claimUI = () => {
        return (
            <div className="text-center my-5">
                <p className="client-address mb-5">
                    🎭 Votre adresse : <Account account={account} library={library} /> 🎭
                </p>
                {chainId !== 1 ? (<p className="error">🎭 Veuillez passer sur le réseau Ethereum 🎭</p>)
                    : userNFTs && userNFTs.length >= 1 ? ListNFTs() : <div className="text-center">Vous n'avez aucun mask sur ce wallet</div>}
            </div>
        )
    }

    return (
        <Container className="mb-5">
            <Meta title={pageTitle} />
            <p className="text-center m-4">Commandez une version physique, pour votre mask.<br />Chaque NFT ne dispose que d'une seule version physique.<br />Une seule commande par année.</p>
            {account ? claimUI() : null}
            <WalletConnect provider={provider} props={props} />
        </Container>
    )
}

export default ClaimZodiacPhysical;