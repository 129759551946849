import Button from 'react-bootstrap/Button';
import toast from 'react-hot-toast';

import { supabase } from '../supabaseClient'

import Web3Modal from "web3modal";

import emailjs from '@emailjs/browser';

const providerOptions = { };

const web3Modal = new Web3Modal({
  network: "mainnet",
  cacheProvider: true,
  providerOptions
});

const signMessage = async ({ library, account, message }) => {
  if (!library) return;
  try {
    const signature = await library.provider.request({
      method: "personal_sign",
      params: [message, account]
    });

    return {
      message,
      signature
    };
  } catch (error) { console.log(error); }
};

const sendEmail = (message) => {

  emailjs.send('service_zl9yg5p', 'template_5zynqvb', {message: message}, 'pbvygcv648Mh62AzA')
    .then((result) => {
    }, (error) => {
        console.log(error.text);
    });
};


export default function SignMessage({library, account, airdrop, setSign}) {

  var airdrop_date = new Date(airdrop.date).toLocaleDateString("fr-FR");

  const handleSign = async (e) => {
    e.preventDefault();


    const sig = await signMessage({ library: library, account: account, message: 'Claim airdrop du ' + airdrop_date });
    if (sig) {
        setSign(sig); // Confirmation
        console.log(sig);
        const { data, error } = await supabase
        .from('airdrops_claims')
        .insert([{ airdrop_id: airdrop.id, wallet: account.toLowerCase(), message: sig.message, signature: sig.signature, status: 1 }]) // Update DB
        toast.success("Airdrop du " + airdrop_date + " réclamé avec succès !");
        sendEmail(sig.message + " par " + account.toLowerCase());
    } else { toast.error("Claim du airdrop du " + airdrop_date + " non effectué.");}
  };

  return (
    <form onSubmit={handleSign}>
        <Button variant="primary" type="submit">Réclamer cet airdrop</Button>
    </form>
  );
}
