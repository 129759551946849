import { useEffect, useState } from 'react';
import { Container, Row, Col, Alert, Card, Button } from "react-bootstrap";

import { supabase } from '../supabaseClient'

import Meta from '../components/Meta'
import CheckForm from '../components/CheckForm';

import WhitelistedZodiacMasks from '../assets/lists/wl-zodiacmasks.json';

const TCPSC = process.env.REACT_APP_TCP_SC_ADDRESS;
const ZMSC = process.env.REACT_APP_ZM_SC_ADDRESS;

const pageTitle = 'The Cryptomasks Project - Claim Service - Check a mask'

function CheckPhysical() {

    const [requestedNFT, setRequestedNFT] = useState()

    const ViewNFT = ({ mask_id, mask_type }) => {
        mask_id = parseInt(mask_id); // Permet d'éviter les crashs en cas d'ajout de zéro devant l'ID
        const [maskDBDatas, setMaskDBDatas] = useState({});

        useEffect(() => { getMaskDBDatas(mask_id, mask_type).then(setMaskDBDatas); }, [mask_id]);

        return (<Col sm={{ span: 4 }}><Card className="mb-4">
            <Card.Img variant="top" src={`https://assets.thecryptomasks.xyz/images/${mask_type === 'TCP' ? 'TheCryptomasksProject' : 'Zodiacmasks'}/${mask_id}.jpg`} />
            <Card.Body>
                <Card.Title>{mask_type === 'TCP' ? 'Cryptomasks' : 'Zodiacmasks'} #{mask_id}</Card.Title>
                {maskDBDatas.status === 0 && (mask_type == 'TCP' || (mask_type == 'ZM' && WhitelistedZodiacMasks.some(mask => parseInt(mask) === parseInt(mask_id)))) ? (<Alert className='mt-4' variant="success">Commande version physique possible</Alert>) : null}
                {mask_type == 'ZM' && !WhitelistedZodiacMasks.some(mask => parseInt(mask) === parseInt(mask_id)) ? (<Alert className='mt-4' variant="danger">Zodiacmask non éligible à la version physique</Alert>) : null}
                {maskDBDatas.status === 1 ? (<Alert className='mt-4' variant="danger">Version physique commandée le {new Date(maskDBDatas.claim_date).toLocaleDateString("fr-FR")}</Alert>) : null}
                <Button href={`https://opensea.io/assets/ethereum/${mask_type === 'TCP' ? TCPSC : ZMSC}/${mask_id}`} target="_blank" variant="primary" size="sm">Voir sur OpenSea</Button>
            </Card.Body>
        </Card></Col>)
    }

    const getMaskDBDatas = async (mask_id, mask_type) => {
        try {
            let { data, error, status } = await supabase
                .from('check_mask_status')
                .select('*')
                .eq('mask_id', mask_id)
                .eq('mask_type', mask_type)

            if (error && status !== 406) { throw error }

            if (data.length >= 1) {
                return (data[0])
            } else { return ({ wallet: null, status: 0, claim_date: null }) }

        } catch (error) { console.log(error.message) } finally { }
    }

    const checkUI = () => {
        return (
            <div className="text-center my-5">
                <CheckForm setRequestedNFT={setRequestedNFT} />
                <Row className='justify-content-center'>{requestedNFT ? (<>
                    {requestedNFT >= 0 && requestedNFT <= 554 && (<ViewNFT mask_id={requestedNFT} mask_type='TCP' />)}
                    {requestedNFT >= 1 && requestedNFT <= 555 && (<ViewNFT mask_id={requestedNFT} mask_type='ZM' />)}
                </>) : null}</Row>
            </div>
        )
    }

    return (
        <Container className="mb-5">
            <Meta title={pageTitle} />
            <p className="text-center m-4">Vérifiez instantanément si un Cryptomask ou un Zodiacmask a bénéficié d'une édition physique antérieure ou non.<br />Chaque NFT ne dispose que d'une seule version physique.</p>
            <div>{checkUI()}</div>
        </Container>
    )
}

export default CheckPhysical;