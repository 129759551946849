import { useEffect, useState, memo } from 'react';
import { supabase } from '../supabaseClient'

const Permissions = memo(({ account, setIsAuthorizedAdminWallet }) => {

    useEffect(() => { getIsAuthorizedAdminWallet(account); }, [account])

    const getIsAuthorizedAdminWallet = async (account) => {
        try {
        if (account) {
            let { data, error, status } = await supabase.rpc('is_authorized_admin_wallet', { wallet: account })
            if (error && status !== 406) { throw error }
            setIsAuthorizedAdminWallet(data);
        }
          } catch (error) { console.log(error.message) } finally { }
      }
    }, (prevProps, nextProps) => { if (prevProps.account === nextProps.account) { return true; } else { return false; } });

export default Permissions