import {useEffect} from 'react';

import { ethers } from "ethers";
import Web3Modal from "web3modal";

const providerOptions = { };

const web3Modal = new Web3Modal({
  network: "mainnet",
  cacheProvider: true,
  providerOptions
});

const WalletConnect = ({props}) => {

  const {
    provider, setProvider,
    setLibrary,
    setSigner,
    account, setAccount,
    error, setError,
    setChainId,
    setUserNFTs,
    setDbNFTs
  } = props

    const connectWallet = async () => {
        try {
          const provider = await web3Modal.connect();
          const library = new ethers.providers.Web3Provider(provider);
          const signer = library.getSigner();
          const accounts = await library.listAccounts();
          const network = await library.getNetwork();
          setProvider(provider);
          setLibrary(library);
          setSigner(signer);
          if (accounts) setAccount(accounts[0]);
          setChainId(network.chainId);
        } catch (error) {
          setError(error);
        }
      };
    
      const refreshState = () => {
        setAccount();
        setChainId();
        setUserNFTs();
        setDbNFTs();
      };
    
      const disconnect = async () => {
        await web3Modal.clearCachedProvider();
        refreshState();
      }; 

    const connectWalletButton = () => {
        return (
            <div className="text-center my-5">
                <button onClick={connectWallet} className='btn btn-success'>Connecter son wallet</button>
            </div>
        )
    }

    const disconnectWalletButton = () => {
      return (
          <div className="text-center">
              <button onClick={disconnect} className='btn btn-sm btn-secondary'>Déconnexion</button>
          </div>
      )
  }
    
      useEffect(() => {
        if (web3Modal.cachedProvider) {
          connectWallet();
        }
      }, []);

    useEffect(() => {
      if (provider?.on) {
        const handleAccountsChanged = (accounts) => {
          console.log("accountsChanged", accounts);
          if (accounts) setAccount(accounts[0]);
        };
  
        const handleChainChanged = (_hexChainId) => {
          setChainId(_hexChainId);
        };
  
        const handleDisconnect = () => {
          console.log("disconnect", error);
          disconnect();
        };
  
        provider.on("accountsChanged", handleAccountsChanged);
        provider.on("chainChanged", handleChainChanged);
        provider.on("disconnect", handleDisconnect);
        return () => {
          if (provider.removeListener) {
            provider.removeListener("accountsChanged", handleAccountsChanged);
            provider.removeListener("chainChanged", handleChainChanged);
            provider.removeListener("disconnect", handleDisconnect);
          }
        };
      }
    }, [provider]);

      return (<div>{!account ? connectWalletButton() : disconnectWalletButton()}</div>)
    }

export default WalletConnect;