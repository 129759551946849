import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { Nav, Container } from "react-bootstrap";

import { House, Gift, Search, Easel, PersonLinesFill } from 'react-bootstrap-icons';

import Logo from "../assets/logo.png";

const Menu = () => {

  const location = useLocation();
  const [url, setUrl] = useState(null);
  useEffect(() => { setUrl(location.pathname); }, [location]);

  return (
    <Container className="header-container" fluid>
    <Container>
      <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
        <Link to="/" className="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white">
          <img src={Logo} alt="The Cryptomasks Project" width="125" />
        </Link>
        <Nav activeKey={location.pathname}>
          <ul className="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
            <li className="m-1 m-md-3 text-center">
              <Link to="/" className={(url === "/" ? " active" : "")}>
                <House className='d-block mx-auto mb-1' size={24} />Accueil<br />Site
              </Link>
            </li>
            <li className="m-1 m-md-3 text-center">
              <Link to="/claim-airdrop" className={(url === "/claim-airdrop" ? " active" : "")}>
              <Gift className='d-block mx-auto mb-1' size={24} /> Claim<br />Airdrop
              </Link>
            </li>
            <li className="m-1 m-md-3 text-center">
              <Link to="/check-physical" className={(url === "/check-physical" ? " active" : "")}>
              <Search className='d-block mx-auto mb-1' size={24} /> Vérification<br />Mask
              </Link>
            </li>
            <li className="m-1 m-md-3 text-center">
              <Link to="/claim-physical" className={(url === "/claim-physical" ? " active" : "")}>
                <Easel className='d-block mx-auto mb-1' size={24} /> Commande<br />Éd. Physique
              </Link>
            </li>
            <li className="m-1 m-md-3 text-center">
              <Link to="/profile" className={(url === "/profile" ? " active" : "")}>
                <PersonLinesFill className='d-block mx-auto mb-1' size={24} /> Gestion<br />Profil
              </Link>
            </li>
          </ul>
        </Nav>
      </header>
    </Container>
    </Container>
  );
};

export default Menu;