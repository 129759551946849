import { useEffect, useState } from 'react';
import { Container, Row, Col, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Twitter, Discord, HouseFill } from 'react-bootstrap-icons';

const version = process.env.REACT_APP_VERSION;

const Footer = ({props}) => {

  const { isAuthorizedAdminWallet } = props
  const year = new Date().getFullYear();

  const location = useLocation();
  const [url, setUrl] = useState(null);
  useEffect(() => { setUrl(location.pathname); }, [location]);

  return (
    <>
      <footer className='text-center text-capitalize mt-auto'>
      <Container fluid>
        <Row>
          <Col lg={12} className="mb-4">

          <Nav>
          <ul className="nav col-12 mb-4 justify-content-center tcp-sn">
          <li>
              <a href="https://linktr.ee/thecryptomasks" target="_blank" rel="noreferrer" className="px-2">
                <HouseFill />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/TheCryptomasks/" target="_blank" rel="noreferrer" className="px-2">
                <Twitter />
              </a>
            </li>
            <li>
              <a href="https://discord.gg/EVCkNzAns2" target="_blank" rel="noreferrer" className="px-2">
              <Discord />
              </a>
            </li>
            {isAuthorizedAdminWallet ? (<li><Link to="/admin" className={"px-2" + (url === "/admin" ? " active" : "")}>🛠</Link></li>): null }
          </ul>
        </Nav>

          <h3>The Cryptomasks Project ~ Claim Service (Version {version})</h3>
          <small>© {year} <a href="https://www.thecryptomasks.xyz">The Cryptomasks Project</a>. <span>Made with <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-heart-fill" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"></path></svg> by <a href="https://profile.rpgmax.fr" target="_blank">RpGmAx</a></span></small>

          </Col>

        </Row> 
        </Container>
        
      </footer>
    </>
  )
}

export default Footer