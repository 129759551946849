import { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card } from "react-bootstrap";

import { ethers } from "ethers";
import contract from '../contracts/TCP.json';

import { supabase } from '../supabaseClient'
import axios from 'axios';

import Meta from '../components/Meta'
import SignAirdrop from '../components/SignAirdrop';
import Account from '../components/Account';
import WalletConnect from '../components/WalletConnect';

const pageTitle = 'The Cryptomasks Project - Claim Service - Airdrop Claim'
const contractAddress = "0xb45d21dc0c1cf275f790b668c3ae09ac5677b740";
const abi = contract.abi;

function ClaimAirdrop({props}) {

  const {
    provider,
    library,
    signer,
    account,
    chainId,
    airdrops, setAirdrops,
    sign, setSign
  } = props

const getAirdrops = async() => {
    try {
        let { data, error, status } = await supabase
        .from('airdrops')
        .select('*')
        .order('date', { ascending: false })

        if (error && status !== 406) { throw error }

        if (data.length >= 1) { setAirdrops(data); } else { setAirdrops() }
        
        } catch (error) { console.log(error.message) } finally { }
}


    const SingleAirdrop = ({ airdrop }) => {

        const [airdropsClaimsDBDatas, setAirdropsClaimsDBDatas] = useState({});
        const [isAuthorizedWallet, setIsAuthorizedWallet] = useState(false);

        useEffect(() => {
            getAirdropsClaimsDBDatas(airdrop.id).then(setAirdropsClaimsDBDatas);
        }, [airdrop]);

        // Vérif gagnant
        var isWinner = airdrop.winners.some(winner => winner.toLowerCase() === account.toLowerCase());

        // Vérif date
        function isBefore(date1, date2) { return date1 < date2; }
        var isDateValid = isBefore(new Date(), new Date(airdrop.max_claim_date));

        return (<Col sm={4} className='mb-4'><Card className="h-100">
        <Card.Img variant="top" src={airdrop.img} />
        <Card.Body>
            <Card.Title>Airdrop du {new Date(airdrop.date).toLocaleDateString("fr-FR")}</Card.Title>
            <h5 className="mb-2">{airdrop.gain}</h5>
            <h5 className="mb-4"><small><i>Date d'expiration du claim : {new Date(airdrop.max_claim_date).toLocaleDateString("fr-FR")}</i></small></h5>
            {airdropsClaimsDBDatas.status === 0 ? (
                (isWinner === true) ? (
                    (isDateValid === true) ? (
                    <SignAirdrop airdrop={airdrop} library={library} account={account} setSign={setSign} />) : (<Button variant="danger" disabled>Airdrop remporté mais délai expiré</Button>)
                ) : (<Button variant="danger" disabled>Airdrop non remporté</Button>)
                ) : null}
            {airdropsClaimsDBDatas.status === 1 ? (<Button variant="success" disabled>Claim effectué le {new Date(airdropsClaimsDBDatas.claim_date).toLocaleDateString("fr-FR")}</Button>) : null}
        </Card.Body>
        </Card></Col>)
    }

    const ListAirdrops = () => {
        const listItems = airdrops.map((airdrop) => <SingleAirdrop key={airdrop.id} airdrop={airdrop} />);
        //console.log(listItems);
        return ( <Row className="justify-content-md-center">{listItems}</Row>);
    }

    const getAirdropsClaimsDBDatas = async (airdrop_id) => {
        try {
            let { data, error, status } = await supabase
            .from('check_airdrop_claim_status')
            .select('*')
            .eq('airdrop_id', airdrop_id)
            .eq('wallet', account.toLowerCase())

            //console.log(mask_id, data);

            if (error && status !== 406) { throw error }
            
            if (data.length >= 1) { return(data[0]) } else { return({wallet: null, status: 0, claim_date: null}) }

            } catch (error) { console.log(error.message) } finally { }
      }

    const claimUI = () => {
        return (
            <div className="text-center my-5">
                <p className="client-address mb-5">
                🎭 Votre adresse : <Account account={account} library={library} /> 🎭
                </p>
                { chainId !== 1 ? ( <p className="error">🎭 Veuillez passer sur le réseau Ethereum 🎭</p> )
                : airdrops ? ListAirdrops() : <div className="text-center">Aucun airdrop effectué pour le moment, revenez prochainement !</div> }
            </div>
        )
    }
          
    useEffect(() => { getAirdrops(); }, [account, sign])    

    return (
        <Container className="mb-5">
            <Meta title={pageTitle}/>
            <p className="text-center m-4">Cette page vous permet de visualiser l'ensemble des airdrops et vos gains éventuels.</p>
            {account ? claimUI() : null }
            <WalletConnect provider={provider} props={props} />
        </Container>
    )
}

export default ClaimAirdrop;