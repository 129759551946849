import Button from 'react-bootstrap/Button';
import toast from 'react-hot-toast';

import { supabase } from '../supabaseClient'

import Web3Modal from "web3modal";

const providerOptions = { };

const web3Modal = new Web3Modal({
  network: "mainnet",
  cacheProvider: true,
  providerOptions
});

const signMessage = async ({ library, account, message }) => {
  if (!library) return;
  try {
    const signature = await library.provider.request({
      method: "personal_sign",
      params: [message, account]
    });

    return {
      message,
      signature
    };
  } catch (error) { console.log(error); }
};

export default function SignMessage({library, setEmail, account, setSign}) {

  const handleSign = async (e) => {
    e.preventDefault();

    const sig = await signMessage({ library: library, account: account, message: 'Profil TCP' });

    if (sig) {
        setSign(sig); // Confirmation
        const { data, error } = await supabase
        .rpc('get_profile', { wallet: account.toLowerCase(), signature: sig.signature })
        if (data) {
            console.log('in');
            setEmail(data.email);
            toast.success("Profil récupéré avec succès !");
        } else { console.log('out'); toast.error("Profil introuvable !"); }
    } else { toast.error("Récupération du profil annulée.");}
  };

  return (
    <form onSubmit={handleSign} className="mb-4">
        <Button variant="secondary" type="submit">Charger/Vérifier son profil</Button><br />
    </form>
  );
}