import { Container } from "react-bootstrap";

import Meta from '../components/Meta'
import Logo from '../assets/logo.png';

import Airdrop from '../assets/airdrop.jpg';

/*import Auth from '../Auth'
import Account from '../Account'*/

const Home = () => {

  const pageTitle = 'The Cryptomasks Project - Claim Service'

  return (
    <div>
      <Meta title={pageTitle}/>
      <div id="banner" className="d-flex align-items-center justify-content-center d-none"><img src={Logo} className="img-fluid" alt={pageTitle}/></div>

      <Container>
        <p className="text-center m-4">Ce site permet de claim le gain d'un airdrop mensuel mais aussi de commander une version physique d'un mask éligible.</p>
        <p className="text-center m-4"><img src={Airdrop} className="img-fluid" /></p>        
      </Container>
      
    </div>
  )
}

export default Home