import { useEffect, useState } from 'react';
import { Container, Row, Col, Alert, Card, Button } from "react-bootstrap";

import { supabase } from '../supabaseClient'

import Meta from '../components/Meta'
import ProfileForm from '../components/ProfileForm';
import Account from '../components/Account';
import WalletConnect from '../components/WalletConnect';

const pageTitle = 'The Cryptomasks Project - Claim Service - Profile Manager'

function Profile({props}) {

    const {
        provider,
        library,
        signer,
        account,
        chainId,
        airdrops, setAirdrops,
        sign, setSign
      } = props

    const [requestedNFT, setRequestedNFT] = useState()

    const profileUI = () => {
        return (
            <div className="text-center my-5">
              <ProfileForm library={library} account={account} setSign={setSign} />
              <Row>{ requestedNFT ? <>RAS</> : null }</Row>
            </div>
        )
    }
    
    return (
        <Container className="mb-5">
            <Meta title={pageTitle}/>
            <p className="text-center m-4">Rattachez simplement et de manière sécurisée un email (correspondant à un compte Paypal valide) à votre wallet.<br />Cette opération est requise pour recevoir votre édition physique (vous serez alors contacté par TheCryptomath).</p>
            {account ? profileUI() : null }
            <WalletConnect provider={provider} props={props} />
        </Container>
    )
}

export default Profile;