import { useEffect, useState } from 'react';
import { Container, Row, Col, Table } from "react-bootstrap";

import { supabase } from '../supabaseClient'

import Meta from '../components/Meta';
import Account from '../components/Account';
import WalletConnect from '../components/WalletConnect';

import BlacklistedMasks from '../assets/lists/bl-masks.json';
import BlacklistedZodiacMasks from '../assets/lists/bl-zodiacmasks.json';

const TCPSC = process.env.REACT_APP_TCP_SC_ADDRESS;
const ZMSC = process.env.REACT_APP_ZM_SC_ADDRESS;

const pageTitle = 'The Cryptomasks Project - Claim Service - Admin panel'

const Admin = ({ props }) => {

  const [dbWalletsOwners, setDbWalletsOwners] = useState([])

  const {
    library,
    account,
    chainId,
    airdrops, setAirdrops,
    dbNFTs, setDbNFTs,
    isAuthorizedAdminWallet
  } = props

  const getNFTs = async () => {
    try {
      let { data, error, status } = await supabase
        .from('masks_list')
        .select('*')
        .order('claim_date', { ascending: false })

      if (error && status !== 406) { throw error }
      setDbNFTs(data);

    } catch (error) { console.log(error.message) } finally { }
  }

  const getWalletsOwners = async () => {
    try {
      let { data, error, status } = await supabase
        .from('wallets_owners')
        .select('*')

      if (error && status !== 406) { throw error }

      let output = Object.keys(data).reduce(function (own, key) {
        var new_owner = {};
        new_owner.email = data[key].email;
        new_owner.signature = data[key].signature;
        own[data[key].wallet] = new_owner;
        return own;
      }, {});

      setDbWalletsOwners(output);

    } catch (error) { console.log(error.message) } finally { }
  }

  const getAirdrops = async () => {
    try {
      let { data, error, status } = await supabase
        .from('airdrops')
        .select('*')
        .order('date', { ascending: false })

      if (error && status !== 406) { throw error }
      setAirdrops(data);

    } catch (error) { console.log(error.message) } finally { }
  }

  const getMaskDBDatas = async (mask_id, mask_type) => {
    try {
      let { data, error, status } = await supabase
        .from('masks_list_datas')
        .select('*')
        .eq('mask_id', mask_id)
        .eq('mask_type', mask_type)

      if (error && status !== 406) { throw error }

      if (data.length >= 1) {
        return (data[0])
      } else { return ({ wallet: null, status: 0, claim_date: null }) }

    } catch (error) { console.log(error.message) } finally { }
  }

  const getAirdropWinnerDBDatas = async (airdrop_id, wallet) => {
    try {
      let { data, error, status } = await supabase
        .from('airdrops_claims')
        .select('*')
        .eq('airdrop_id', airdrop_id)
        .eq('wallet', wallet.toLowerCase())

      if (error && status !== 406) { throw error }

      if (data.length >= 1) {
        return (data[0])
      } else { return ({ wallet: null, status: 0, claim_date: null }) }

    } catch (error) { console.log(error.message) } finally { }
  }

  const SingleAirdrop = ({ airdrop }) => {
    return (<tr>
      <td>{airdrop.id}</td>
      <td>Tirage : {new Date(airdrop.date).toLocaleDateString("fr-FR")}<br />Limite : {new Date(airdrop.max_claim_date).toLocaleDateString("fr-FR")}</td>
      <td>{airdrop.gain}</td>
      <td>{ListAirdropWinners(airdrop)}</td>
    </tr>)
  }

  const SingleAirdropWinner = ({ airdrop, wallet }) => {

    const [airdropWinnerDBDatas, setAirdropWinnerDBDatas] = useState({});
    const [verifyMaskSignature, setVerifyMaskSignature] = useState();

    useEffect(() => {
      getAirdropWinnerDBDatas(airdrop.id, wallet).then(setAirdropWinnerDBDatas);
    }, [wallet]);

    useEffect(() => {
      verifyMessage({ message: airdropWinnerDBDatas.message, address: airdropWinnerDBDatas.wallet, signature: airdropWinnerDBDatas.signature }).then(setVerifyMaskSignature);
    }, [airdropWinnerDBDatas]);

    // Vérif date
    function isBefore(date1, date2) { return date1 < date2; }

    return (<tr>
      <td><a href={`https://etherscan.io/address/${wallet}`} target="_blank" rel="noreferrer">{wallet}</a></td>
      <td>{airdropWinnerDBDatas.claim_date ? new Date(airdropWinnerDBDatas.claim_date).toLocaleDateString("fr-FR") : null}</td>
      <td>{isBefore(airdropWinnerDBDatas.claim_date, airdrop.max_claim_date) ? (<span>✔️</span>) : (<span>❌</span>)}</td>
      <td>{verifyMaskSignature ? (<span>✔️</span>) : (<span>❌</span>)}</td>
    </tr>)
  }


  const SingleNFT = ({ mask_id, mask_type }) => {

    const [maskDBDatas, setMaskDBDatas] = useState({});
    const [verifyProfileSignature, setVerifyProfileSignature] = useState();
    const [verifyMaskSignature, setVerifyMaskSignature] = useState();
    const [verifyBLMasks, setVerifyBLMasks] = useState();

    useEffect(() => {
      getMaskDBDatas(mask_id, mask_type).then(setMaskDBDatas);
    }, [mask_id]);

    var maskTypeFull;
    if (mask_type === 'TCP') { maskTypeFull = 'Cryptomasks' } else { maskTypeFull = 'Zodiacmasks' }

    useEffect(() => {
      verifyMessage({ message: maskDBDatas.message, address: maskDBDatas.wallet, signature: maskDBDatas.signature }).then(setVerifyMaskSignature);
      if (dbWalletsOwners[maskDBDatas.wallet]) { /*console.log(maskDBDatas.wallet + '---' + dbWalletsOwners[maskDBDatas.wallet].signature);*/ verifyMessage({ message: 'Profil TCP', address: maskDBDatas.wallet, signature: dbWalletsOwners[maskDBDatas.wallet].signature }).then(setVerifyProfileSignature); }
      verifyBL(mask_id, mask_type).then(setVerifyBLMasks);
    }, [maskDBDatas]);

    return (<tr>
      <td>{maskTypeFull}</td>
      <td><a href={`https://opensea.io/assets/ethereum/${mask_type === 'TCP' ? TCPSC : ZMSC}/${mask_id}`} target="_blank" rel="noreferrer">{mask_id}</a></td>
      <td>{new Date(maskDBDatas.claim_date).toLocaleDateString("fr-FR")}</td>
      <td><a href={`https://etherscan.io/address/${maskDBDatas.wallet}`} target="_blank" rel="noreferrer">{maskDBDatas.wallet}</a></td>
      <td className="break">{dbWalletsOwners[maskDBDatas.wallet] ? (dbWalletsOwners[maskDBDatas.wallet].email) : null}</td>
      <td>{verifyProfileSignature ? (<span>✔️</span>) : (<span>❌</span>)}</td>
      <td>{verifyMaskSignature ? (<span>✔️</span>) : (<span>❌</span>)}</td>
      <td>{verifyBLMasks === false ? (<span>✔️</span>) : (<span>❌</span>)}</td>
    </tr>)
  }

  const verifyMessage = async ({ message, address, signature }) => {

    if (!library || !address) return;

    try {
      const verify = await library.provider.request({
        method: "personal_ecRecover",
        params: [message, signature]
      });
      if (verify === address.toLowerCase()) { return true; } else { return false; }
    } catch (error) { }
  };

  const verifyBL = async (mask_id, mask_type) => {
    if (!mask_id) return;
        var BL;
        if (mask_type === 'TCP') { BL = BlacklistedMasks } else { BL = BlacklistedZodiacMasks }
        return BL.some(mask => parseInt(mask) === parseInt(mask_id));
  };

  const ListAirdrops = () => {
    const listItems = airdrops.map((airdrop) => <SingleAirdrop key={airdrop.id} airdrop={airdrop} />);
    return (<Row><Col lg={12}><Table responsive striped bordered hover variant="dark" className="admin-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Dates</th>
          <th>Gain</th>
          <th>Winners</th>
        </tr>
      </thead>
      <tbody>{listItems}</tbody>
    </Table></Col></Row>);
  }

  const ListAirdropWinners = (airdrop) => {
    const listItems = airdrop.winners.map((wallet, index) => <SingleAirdropWinner key={index} airdrop={airdrop} wallet={wallet} />);
    return (<Row><Col lg={12}><Table responsive striped bordered hover variant="dark" className="admin-table">
      <thead>
        <tr>
          <th>Wallet</th>
          <th>Date du claim</th>
          <th>Vérification date</th>
          <th>Vérification signature</th>
        </tr>
      </thead>
      <tbody>{listItems}</tbody>
    </Table></Col></Row>);
  }

  const ListNFTs = () => {
    const listItems = dbNFTs.map((nft) => <SingleNFT key={nft.mask_id} mask_id={nft.mask_id} mask_type={nft.mask_type} />);
    return (<Row><Col lg={12}><Table responsive striped bordered hover variant="dark" className="admin-table">
      <thead>
        <tr>
          <th>Type</th>
          <th>ID</th>
          <th>Date</th>
          <th>Wallet</th>
          <th>Email</th>
          <th>Signature Profil</th>
          <th>Signature Claim</th>
          <th>Adresse WL</th>
        </tr>
      </thead>
      <tbody>{listItems}</tbody>
    </Table></Col></Row>);
  }

  const adminUI = () => {
    return (
      <div className="text-center my-5">
        <p className="client-address mb-5">
          🎭 Votre adresse : <Account account={account} library={library} /> 🎭
        </p>
        {chainId !== 1 ? (<p className="error">🎭 Veuillez passer sur le réseau Ethereum 🎭</p>)
          : airdrops && dbNFTs ? (<><h5>Airdrops</h5>{ListAirdrops()}<hr /><h5>Claim des versions physiques</h5>{ListNFTs()}</>) : <div className="text-center">Vous n'êtes pas autorisé à consulter cette page</div>}
      </div>
    )
  }

  useEffect(() => { if (isAuthorizedAdminWallet == true) { getAirdrops(); getNFTs(); getWalletsOwners(); } else { setAirdrops(); setDbNFTs(); } }, [isAuthorizedAdminWallet])

  return (
    <Container className="mb-5">
      <Meta title={pageTitle} />
      <p className="text-center m-4">Page d'administration - Réservée aux membres autorisés</p>
      <div>{account ? adminUI() : <WalletConnect props={props} />}</div>
    </Container>
  )
}

export default Admin;