import { useEffect, useState, memo } from 'react';
import { supabase } from '../supabaseClient'

const Account = memo(({ account, library }) => {

    const getWalletName = async () => {
        try { return library.lookupAddress(account).then(function(name) { if (name) { return name } else { return account }; }); } catch (error) { console.log(error); }
    }
    
    const getAccountName = async () => {

        try {
            let { data, error, status } = await supabase
            .from('admins')
            .select('username')
            .ilike('wallet', '%' + account + '%')

            if (error && status !== 406) { throw error }
            
            if (data.length >= 1) {
                return('🛠 ' + data[0].username + ' - ')
            } else { return('') }

            } catch (error) { console.log(error.message) } finally { }
    }

    const DisplayWalletAddress = ({account}) => {

        const [walletName, setWalletName] = useState('Chargement en cours');
        const [accountName, setAccountName] = useState('');

        useEffect(() => {
            getAccountName().then(setAccountName);
            getWalletName().then(setWalletName);
        }, [account]);

        return(<a href={`https://etherscan.io/address/${account}`} target="_blank" rel={"noreferrer"}>{accountName}{walletName}</a>)
    }

  return (<DisplayWalletAddress account={account} />)
}, (prevProps, nextProps) => { if (prevProps.account === nextProps.account) { return true; } else { return false; } });

export default Account