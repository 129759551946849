import { useState } from "react";
import { Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import SignProfileView from '../components/SignProfileView';
import SignProfileUpdate from '../components/SignProfileUpdate';

function ProfileForm({ library, account, setSign }) {
  const [email, setEmail] = useState('');

  return (
    <Row className="align-items-center text-center">
    <Col lg={{ span: 8, offset: 2 }} className="mb-4">      
      <InputGroup className="mb-4">
        <InputGroup.Text>Votre wallet :</InputGroup.Text>
        <Form.Control type="text" name="wallet" disabled placeholder="Votre wallet" value={account} />
      </InputGroup>
      <InputGroup className="mb-4">
        <InputGroup.Text>Votre email Paypal  :</InputGroup.Text>
        <Form.Control type="text" name="wallet" placeholder="Saisissez ou chargez votre email (si déjà enregistré)" onChange={e => setEmail(e.target.value)} value={email} />
      </InputGroup>
    </Col>

    <SignProfileView library={library} setEmail={setEmail} account={account} setSign={setSign} />
    <SignProfileUpdate library={library} account={account} email={email} setSign={setSign} />

    </Row>
)
}

export default ProfileForm