import Button from 'react-bootstrap/Button';
import toast from 'react-hot-toast';

import { supabase } from '../supabaseClient'

import Web3Modal from "web3modal";

const providerOptions = { };

const web3Modal = new Web3Modal({
  network: "mainnet",
  cacheProvider: true,
  providerOptions
});

const signMessage = async ({ library, account, message }) => {
  if (!library) return;
  try {
    const signature = await library.provider.request({
      method: "personal_sign",
      params: [message, account]
    });

    return {
      message,
      signature
    };
  } catch (error) { console.log(error); }
};

export default function SignMessage({library, account, email, setSign}) {

  const handleSign = async (e) => {
    e.preventDefault();


    const sig = await signMessage({ library: library, account: account, message: 'Profil TCP' });

    if (sig) {
        setSign(sig); // Confirmation
        const { data, error } = await supabase
        .rpc('update_profile', { wallet: account.toLowerCase(), email: email, signature: sig.signature })
        toast.success("Mise à jour du profil effectuée avec succès !");
    } else { toast.error("Échec lors de la mise à jour du profil !");}
  };

  return (
    <form onSubmit={handleSign} className="mb-4">
        <Button variant="primary" type="submit">Mettre à jour son profil</Button>
    </form>
  );
}