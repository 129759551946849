import { useState } from "react";
import { Row, Col, Form, Button, InputGroup } from "react-bootstrap";

function CheckForm({ setRequestedNFT }) {
  const [localRequestedNFT, setLocalRequestedNFT] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    setRequestedNFT(localRequestedNFT);
  }

  return (<Form onSubmit={handleSubmit} className="m-4">
    <Row className="align-items-center text-center">
    <Col lg={{ span: 3, offset: 4 }} className="mb-4">      
      <InputGroup>
        <InputGroup.Text>ID du NFT :</InputGroup.Text>
        <Form.Control type="number" name="mask_id" min="0" max="555" placeholder="Saisir l'ID du mask" onChange={e => setLocalRequestedNFT(e.target.value)} />
      </InputGroup>      
    </Col>
    <Col lg={1} className="mb-4"><Button type="submit">Vérifier</Button></Col>
    </Row>
</Form>)
}

export default CheckForm